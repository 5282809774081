<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs12 py-2 text-left>
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="mainHeader">Customer Profile</v-flex>
          
          </v-layout>
          <v-layout wrap pa-4 style="background-color: white;" class="rounded-xl">
            <v-flex xs5 pr-4>
              <v-card height="100%" elevation="0">
                <v-layout wrap >
                  <v-flex xs12 class="tst1">Personal Details</v-flex>
                  <v-flex xs12 class="tst3" v-if="CustomerData.customerName">{{CustomerData.customerName}}</v-flex>
                  <v-flex xs12 sm6 class="tst3" v-if="CustomerData.mobileNumber">{{CustomerData.mobileNumber}}</v-flex>
                  <!-- <v-flex xs12 sm6 class="tst3" v-if="CustomerData.city">{{CustomerData.city}}</v-flex> -->
                  <v-flex xs12 class="tst3" v-if="CustomerData.address">{{CustomerData.address}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs5 px-4>
              <v-card height="100%" elevation="0">
                <v-layout wrap pb-4>
                    <v-flex xs12 ><span class="tst1">Aadhar : </span>
                        <span class="tst3"  v-if="CustomerData.aadharNo">{{CustomerData.aadharNo}}</span>
                        <span class="tst3"  v-else >-</span>
                    </v-flex>
                    </v-layout>
                    <v-layout wrap pb-4>
                  <v-flex xs12  ><span class="tst1">PAN :  </span>
                    <span class="tst3" v-if="CustomerData.panNo">{{CustomerData.panNo}}</span>
                    <span class="tst3"  v-else >-</span>
                </v-flex>
                </v-layout>
                    <v-layout wrap >
                  <v-flex xs12><span class="tst1">Balance Amt(₹) : </span>
                    <span class="tst3"  v-if="CustomerData.customerBalanceAmount" >{{CustomerData.customerBalanceAmount}}</span>
                    <span class="tst3"  v-else >0</span>
                </v-flex>
                
                </v-layout>
                    <!-- <v-layout wrap pb-4>
                  <v-flex xs12 v-if="CustomerData" ><span class="tst1">Total Amt(₹) :  </span><span class="tst3">{{CustomerData}}</span></v-flex>
                </v-layout> -->
              </v-card>
            </v-flex>
          </v-layout>
         
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="subhead4">Gold Schemes
            <!-- </v-flex> 
            <v-flex xs12 sm1 px-4 text-left class="subhead4"> -->
              <!-- <v-btn  small flat text> 
                <v-icon style="cursor:pointer" title="click to print" @click="downloadReciept()">mdi-printer</v-icon>
            </v-btn> -->
          </v-flex>
          
          </v-layout>
          <!-- <v-layout wrap py-1>
                <v-flex xs12 px-4>
                  <v-divider style="border-color:#580554; !important"></v-divider>
                </v-flex>
              </v-layout> -->
          <v-layout wrap pa-4 v-if="schemeList">
              <v-flex xs12 v-if="schemeList.length>0">
                <v-simple-table>
        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed"><b>Name</b></th>
                            <th class="text-left subhed"><b>Monthly Amt.</b></th>
                            <!-- <th class="text-left subhed"><b>Gross.Wt </b></th> -->
                            <th class="text-left subhed"><b>Yearly Interest</b></th>
                            <th class="text-left subhed"><b>Start Date</b></th>
                            <th class="text-left subhed"><b>Mature Date</b></th>
                            <th class="text-left subhed"><b>Paid Amt.(₹)</b></th>
                            <th class="text-left subhed"><b>Status</b></th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in schemeList"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.goldSchemeId">{{
                                value.goldSchemeId.goldSchemeName
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldSchemeId.minmonthlydeposit"> {{ value.goldSchemeId.minmonthlydeposit }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldSchemeId.yearlyIntrest">{{
                                value.goldSchemeId.yearlyIntrest
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.startDate">{{ formatDate(value.startDate) }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.matureDate">{{
                                formatDate(value.matureDate)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.totCustomerDepo">{{
                                value.totCustomerDepo
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.schemeStatus">{{
                                value.schemeStatus
                              }}</span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          
                        </tbody>
                    </v-simple-table>
              </v-flex>
            </v-layout>
           </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        CustomerData:[],
        schemeList:[],
    taxType:localStorage.getItem("GSTstatus"),
      };
    },
    mounted() {
      this.getSales();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    methods: {
      getSales() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/customer/view",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            id:this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.CustomerData = response.data.data;
              this.schemeList = response.data.data.GoldSchemeCustomerId;

            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      formatDate(item) {
        var dt = new Date(item);
        var day = dt.getDate();
        var year = dt.getFullYear();
        // var hours = dt.getHours();
        // var minutes = dt.getMinutes();
        dt = dt.toString();
        // var ampm = hours >= 12 ? "pm" : "am";
        // hours = hours % 12;
        // hours = hours ? hours : 12;
        // minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime =
          day +
          " " +
          dt.slice(4, 7) +
          " " +
          year
          //  +
          // " , " +
          // hours +
          // ":" +
          // minutes +
          // " " +
          // ampm;
  
        return strTime;
      },
    },
  };
  </script>
  